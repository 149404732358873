.container {
  color: #1a1a1a;
  font-family: "Inter", sans-serif;
  height: 100%;
}

.title {
  font-size: 6.4rem;
  font-weight: 100;
  margin-bottom: 4rem;

  span {
    display: inline-block;
    margin: 0;
    opacity: 0;
    position: relative;
  }
}

.hi {
  animation: hi 2s ease-in-out;
  animation-delay: .4s;
  animation-fill-mode: forwards;
  left: 50%;
  transform: translateX(-50%);
}

.im {
  animation: im .8s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  top: .2em;
}

.jean {
  animation: jean 1.6s ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  top: .2em;
  will-change: font-weight;
}

.subtitle {
  animation: ntmu .8s ease-in-out;
  animation-delay: 4s;
  animation-fill-mode: forwards;
  color: #1a1a1a;
  font-size: 2rem;
  font-weight: 200;
  opacity: 0;
  position: relative;
  top: .2em;
}

a {
  color: #1a1a1a;
  text-decoration: none;
}

@keyframes hi {
  0% {
    font-weight: 100;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
  }
  40% {
    left: 50%;
    opacity: 1;
    transform: translateX(-50%);
  }
  60% {
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    left: 0;
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes im {
  0% {
    opacity: 0;
    top: .2em;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes jean {
  0% {
    opacity: 0;
    top: .2em;
  }
  40% {
    font-weight: 100;
  }
  50% {
    opacity: 1;
    top: 0;
  }
  80%, 100% {
    font-weight: 700;
    opacity: 1;
    top: 0;
  }
}

@keyframes ntmu {
  0% {
    top: .2em;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
